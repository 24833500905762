export function isDiscountExpired(profile) {
  if (!!profile.discountCertificate) {
    if (new Date(profile.discountCertificate.timestamp||0) > new Date(profile.discountExpirationDate||0)) {
      return false
    }
    if (!profile.discountExpirationDate) {
      return false
    }
  }
  if (!profile.discountExpirationDate) {
    return false
  }
  if (new Date(profile.discountExpirationDate||0) < new Date()) {
    return true
  }
}
