export function dataToArray(data) {
  return Object.entries(data||[]).map(([key, value]) => ({key, ...value}))
}

export function watchEvent(firebase, {type, path, storeAs, ...params}) {
  if (params.queryParams) params.isQuery = true
  return firebase.watchEvent(type, path, storeAs, params)
}

export function unWatchEvent(firebase, {type, path, queryId, ...params}) {
  return firebase.unWatchEvent(type, path, queryId, params)
}
