import React, { Component } from 'react';
import PropTypes from 'prop-types'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect, isLoaded } from 'react-redux-firebase'

import { Link } from 'react-router-dom';
import Banner from '@atlaskit/banner';
import CalendarIcon from '@atlaskit/icon/glyph/calendar';
import { isDiscountExpired } from '../utils/ProfileHelper';


class UserAlertBanners extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
  }

  render() {
    const { profile } = this.props
    return (
      <>
        {isLoaded(profile) && isDiscountExpired(profile) && (
          <Banner isOpen={true} appearance="warning" icon={<CalendarIcon />}>
            <span>Your discount certificate has expired. </span>
            <Link to="/settings">Read more</Link>
          </Banner>
        )}
      </>
    )
  }
}

export default compose(
  firebaseConnect((props, store) => ([
  ])),
  connect(({firebase: {auth, profile}}, props) => ({
    profile,
  })),
)(UserAlertBanners)
