import { APP_ENV } from '../config'

export function getUrlParam(name, { match: { params }={} }) {
  return params[name]
}

export function getSearch({ location: { search='' }={} }) {
  return search
}

export function getSearchParam(name, { location: { search='' }={} }) {
  let params = {}
  search.substr(1).split('&').map(p => p.split('=')).forEach(([a,b]) => params[a] = b)
  return params[name]
}

export function getHash({ location: { hash } }) {
  return hash.substr(1)
}

export function getPathParts({ location }) {
  return location.pathname.split('/')
}

export function isEmbedded({ location }) {
  return getPathParts({location}).indexOf('embed') !== -1
}

export function isDev() {
  return APP_ENV === 'development'
}
