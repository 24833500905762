import React from 'react';
import Spinner from '@atlaskit/spinner';
import styled from 'styled-components';

const Container = styled.div`
  padding: 3% 5%;
  width: 100%;
  text-align: center;
  &.size-large {
    padding: 3% 5%;
  }
`

export default function LoadingSpinner(props) {
  const {
    loading=true,
    size='large',
    align='center',
  } = props
  if (!loading) return null
  return (
    <Container className={`size-${size} float-${align}`}>
      <div className="floater">
        <Spinner size={size} />
      </div>
    </Container>
  )
}
