import React from 'react';
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import FlexContainer from '../components/FlexContainer'
import { colors } from '../styles/colors'

const FooterPad = styled.div`
  padding-top: 6em;
`
const Footer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid ${colors.secondaryColor};
  padding: 0.4em 1em;
  box-sizing: border-box;
  font-size: 0.8rem;
  background: ${colors.background};

  a, a:hover {
    color: inherit;
  }

  @media screen and (max-width: 480px) {
    .flex {
      display: block;
    }
  }
`

export default function AboutFooter() {
  return (
    <>
      <FooterPad/>
      <Footer>
        <FlexContainer className="flex">
          <nav style={{width: '100%'}}>
            <a href="https://portal.blackpepperswing.com/" target="_blank" rel="noopener noreferrer">BPS Portal</a>
            <span> | </span>
            <a href="https://blackpepperswing.com/contact" target="_blank" rel="noopener noreferrer">Help</a>
            <span> | </span>
            <Link to="/help/faq" target="_blank">FAQ</Link>
            <span> | </span>
            <Link to="/help/terms" target="_blank">Terms & Privacy</Link>
          </nav>
          <div>
            portal.blackpepperswing.com
          </div>
        </FlexContainer>
      </Footer>
    </>
  )
}

//           © {new Date().getFullYear()} Black Pepper Swing
