import React, { Component } from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'

import ContentWrapper from '../components/ContentWrapper';

class DancePassPage extends Component {

  dancePassUrl = 'https://blackpepperswing.com/dance-passes'

  render() {
    window.location.replace(this.dancePassUrl)
    return (
      <ContentWrapper>
        <h1>Dance Pass</h1>
        <p>
          Get the total freedom of dancing with the newly launched dance passes!
        </p>
        <p>
          With the Dance Pass, you can attend all our weekly classes and activities with single payment. All the classes are included, so take as many courses and classes as you like!
        </p>
        <p>
          One pass, all the classes.
        </p>
        <p>
          Take a look:<br/>
          <a href={this.dancePassUrl}>{this.dancePassUrl}</a>
        </p>
      </ContentWrapper>
    )
  }
}

export default compose(
  firebaseConnect((props, store) => ([
  ])),
  connect(({firebase: {data, auth}}, props) => {
  }),
)(DancePassPage)
