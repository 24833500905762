import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect, isLoaded } from 'react-redux-firebase'

import styled from 'styled-components';
import Button from '@atlaskit/button';

import ContentEditor from '../components/ContentEditor';
import EditIcon from '@atlaskit/icon/glyph/edit';

import FlexContainer, { Flex } from '../components/FlexContainer';
import Section from '../components/Section';
import ContentWrapper from '../components/ContentWrapper';
import PageHeader from '../components/PageHeader';
import CardEl, { CardGroup as CardGroupEl } from '../components/styled/Card';
import Markdown from '../components/Markdown';
import LoadingSpinner from '../components/LoadingSpinner';
import RequireRole from '../components/RequireRole';

import { colors } from '../styles/colors';

const Decoration = styled.div`
  z-index: -1;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 24%;
  transform: skewY(-6deg);
  transform-origin: right;
  background-color: ${colors.primaryColor};
`;

const Card = styled(CardEl)`
  width: 70vw;
  min-width: 280px;
  max-width: 380px;
`;

const CardGroup = styled(CardGroupEl)`
`;

const EditButtonIcon = <EditIcon label="Edit" />

export class HomePage extends Component {
  static contextTypes = {
    addFlag: PropTypes.func,
    openModal: PropTypes.func,
    closeModal: PropTypes.func,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    onClose: PropTypes.func,
  };

  static propTypes = {
    firebase: PropTypes.shape({
      ref: PropTypes.func.isRequired,
    })
  }

  constructor(props) {
    super(props)
    this.state = {
      bulletinEdit: {
        id: null,
        content: null
      }
    }
  }

  onChangeBulletinEdit(bulletinId, newContent) {
    this.setState({
      bulletinEdit: {
        id: bulletinId,
        content: newContent
      }
    })
  }

  async saveAndExitBulletin() {
    const { firebase } = this.props
    const { bulletinEdit } = this.state
    await firebase.ref('bulletin').child(bulletinEdit.id).set(bulletinEdit.content)
    this.context.closeModal()
  }

  editBulletin(bulletinId) {
    const { bulletin } = this.props
    this.context.openModal({
      content: (
        <ContentEditor 
          content={bulletin[bulletinId]}
          onChange={(html) => this.onChangeBulletinEdit(bulletinId, html)}
        />
      ),
      actions: [
        { text: 'Save', onClick: this.saveAndExitBulletin.bind(this) },
        { text: 'Cancel', onClick: this.context.closeModal.bind(this) },
      ]
    })
  }

  render() {
    const { profile, bulletin } = this.props
    return (
      <Fragment>
        <Decoration />
        <ContentWrapper fullWidth>
          <PageHeader title="Home" showTitle={true} breadcrumbs="home" />
          <Section style={{minWidth: '30%'}}>
            <h1>
              <RequireRole role="any" orElse={(
                <p>Welcome to BPS Portal</p>
              )}>
                <p>
                  Welcome to BPS Portal,<br />
                  {profile.firstname} {profile.lastname}
                </p>
              </RequireRole>
            </h1>
          </Section>
          <FlexContainer justifyContent="flex-start">
            <Flex>
              <CardGroup>
                <h2>What's new</h2>
                {isLoaded(bulletin) ? (
                  Object.entries(bulletin).map(([id, message]) => (
                    <Card key={id+'-'+message}>
                      <RequireRole role="admin">
                        <div style={{marginBottom: '1em'}}>
                          <div style={{float: 'right'}}>
                            <Button appearance="subtle-link" spacing="compact" iconBefore={EditButtonIcon} onClick={this.editBulletin.bind(this, id)}>Edit</Button>
                          </div>
                        </div>
                      </RequireRole>
                      <Markdown source={message} />
                    </Card>
                  ))
                ) : (
                  <LoadingSpinner />
                )}
                <hr/>
              </CardGroup>
            </Flex>
            <Flex>
              <CardGroup>
                <h2>Your dashboard</h2>
                <RequireRole role="any" orElse={(
                  <Card>
                    <h3>Create your account</h3>
                    <p>
                      Once you have an account, registering for courses is faster. Feel free to browse the course schedule meanwhile.
                    </p>
                    <br/>
                    <Link to="/signup"><Button appearance="primary">Create account</Button></Link>
                    <Link to="/messages"><Button appearance="subtle-link">Already have an account? Sign in.</Button></Link>
                  </Card>
                )}>
                  <Card>
                    <p>
                      Welcome to Portal!
                    </p>
                    <p>
                      You can check your current <Link to="/regs">courses and registration</Link>, view your <Link to="/invoices">invoices</Link> and <Link to="/messages">messages</Link>, and update your <Link to="/settings">settings and profile information</Link> (like dance background).
                    </p>
                    <ul>
                      <li><Link to="/regs">Registrations</Link></li>
                      <li><Link to="/invoices">Invoices</Link></li>
                      <li><Link to="/messages">Messages</Link></li>
                      <li><Link to="/settings">Profile</Link></li>
                    </ul>
                  </Card>
                  { /*
                  <Card>
                    <h3>Current registrations</h3>
                    <Registrations hidePast rowsPerPage={Infinity} />
                    <Link to="/regs"><Button appearance="primary">Show all registrations</Button></Link>
                  </Card>
                  <Card>
                    <h3>Open invoices</h3>
                    <br />
                    <Invoices status="unsettled" showBalance={false} showSummary={false} showDisclaimer={false} rowsPerPage={Infinity} />
                    <Link to="/invoices"><Button appearance="primary">Show all invoices</Button></Link>
                  </Card>
                  <Card>
                    <h3>Latest messages</h3>
                    <Notifications pageSize={2} showPagination={false} />
                    <br/>
                    <Link to="/messages"><Button appearance="primary">Show all messages</Button></Link>
                  </Card>
                  */ }
                </RequireRole>
                <Card>
                  <h3>Contact us</h3>
                  <p>
                    If you have any questions or feedback, please drop us an email or give a call:
                    <br />
                    <br /><b><a href="mailto:info@blackpepperswing.com">info@blackpepperswing.com</a></b>
                    <br /><b><a href="+358841541724">+358 84 154 1724</a></b>
                  </p>
                  <p>
                    <small>
                      You can, of course, contact us also via any of our social network channels. <Link to="/help">Check our more ways to contact us on our support pages here.</Link>
                    </small>
                  </p>
                </Card>
              </CardGroup>
            </Flex>
          </FlexContainer>
        </ContentWrapper>
      </Fragment>
    );
  }
}

export default compose(
  firebaseConnect((props, store) => ([
    { type: 'value', /*once*/ path: 'bulletin' },
  ])),
  connect(({firebase: {profile, auth, data}}, props) => {
    const { bulletin } = data
    return {
      profile,
      bulletin,
    }
  }),
)(HomePage)
