// @flow
import * as React from 'react';

import { sizes } from '../config'

function isDevice(device: string, width: number) {
  if (device === 'desktop') {
    return width > sizes.tablet
  }
  else if (device === 'tablet') {
    return width > sizes.phone && width <= sizes.tablet
  }
  else if (device === 'phone') {
    return width <= sizes.phone
  }
}

function Only({width, devices, children}) {
  if (!devices.some(device => isDevice(device, width))) {
    return ''
  }
  return children
}

type Props = {
  only: string[],
  width?: number,
}

type State = {
  width: number,
  height: number,
}

export default class DeviceDiv extends React.PureComponent<Props, State> {
  state = {
    width: 0,
    height: 0,
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    const width = this.props.width || this.state.width
    return (
      <div>
        <Only devices={this.props.only} width={width}>{this.props.children}</Only>
      </div>
    )
  }
}
