import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'

import styled from 'styled-components';
import AkSpinner from '@atlaskit/spinner';

const LoadingContainer = styled.div`
  text-align: center;
`;

const Spinner = ({ size }) => (
  <LoadingContainer>
    <AkSpinner size={size} />
  </LoadingContainer>
)

export function hasRole(role, profile) {
  if (role && role !== 'any') {
    if (!profile.isLoaded || !profile.roles) {
      return false
    }
    return !!profile.roles[role]
  }
  return true
}

export function isVerified(auth) {
  return !!auth.emailVerified
}

/**
 * RequireRole
 *
 * Usage example:
 * <RequireRole role="admin" orElse={(
 *   <p>Permission denied.</p>
 * )}>
 *   <p>Welcome, admin!</p>
 * </RequireRole>
 *
 * params:
 * role (string)
 * orElse (React element / jsx) - content to display if user role does not match required.
 */
const RequireRole = ({
  role,
  orElse,
  requireVerified=false,
  profile, auth, children
}) => {
  if (!auth.isLoaded) {
    return (<Spinner size="small" />)
  }
  else if (auth.isEmpty) {
    return (orElse || '')
  }

  if (!profile.isLoaded) {
    return (<Spinner size="small" />)
  }

  if (!hasRole(role, profile)) {
    return (orElse || '')
  }
  if (!!requireVerified && !isVerified(auth)) {
    return (orElse || '')
  }

  return children
}

export default compose(
  firebaseConnect((props, store) => ([
  ])),
  connect(({firebase, firebase: {auth, profile}}, props) => {
    return {
      profile: {key: auth.uid, ...profile},
      auth
    }
  }),
)(RequireRole)
