// @flow

import React, { Component } from 'react';
import styled from 'styled-components';

import MobileHeader from '@atlaskit/mobile-header';

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

const SideBar = styled.div`
  background-color: white;
  height: 100vh;
  width: 90vw;
  max-width: 464px;
  overflow: auto;
  > div {
    padding: 2em 1em;
  }
`;

type State = {
  drawerState: 'navigation' | 'sidebar' | 'none',
};

class OurMobileHeader extends Component<*, State> {
  state = {
    drawerState: 'none',
  };

  navOpened = () => {
    this.setState({ drawerState: 'navigation' });
  };

  sidebarOpened = () => {
    this.setState({ drawerState: 'sidebar' });
  };

  drawerClosed = () => {
    this.setState({ drawerState: 'none' });
  };

  closeDrawer() {
    this.setState({
      drawerState: 'none'
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged()
    }
  }

  onRouteChanged() {
    this.closeDrawer()
  }

  render() {
    const { navigation } = this.props
    return (
      <MobileHeader
        drawerState={this.state.drawerState}
        menuIconLabel={'Menu'}
        navigation={isOpen => isOpen && navigation}
        pageHeading="BPS Portal"
        onNavigationOpen={this.navOpened}
        onSidebarOpen={this.sidebarOpened}
        onDrawerClose={this.drawerClosed}
      />
    );
  }
}

export default withRouter(connect(
  ({ firebase: { auth } }) => ({ auth })
)(OurMobileHeader))
