// @flow
import * as React from 'react';
import ReactMarkdown from 'react-markdown/with-html'
import ContentWithMedia from './ContentWithMedia'

function decodeHTML (html) {
  var div = document.createElement('div');
  div.innerHTML = html;
  return div.innerHTML;
}

export function stripHTML(string) {
  return string.replace(/(<([^>]+)>)/ig, '')
}

/**
 * Inspiration: https://muffinresearch.co.uk/removing-leading-whitespace-in-es6-template-strings/
 */
export function singleLineString(strings, ...values) {
  // Interweave the strings with the
  // substitution vars first.
  let output = '';
  for (let i = 0; i < values.length; i++) {
    output += strings[i] + values[i];
  }
  output += strings[values.length];

  // Split on newlines.
  let lines = output.split(/(?:\r\n|\n|\r)/);

  // Rip out the leading whitespace, max to first intended line depth
  let firstPadd = null
  return lines.map((line) => {
    if (firstPadd === null) {
      let match = line.match(/^\s+/gm)
      firstPadd = match ? match[0] : null;
    }
    if (firstPadd === null) {
      return line;
    }
    return line.replace(new RegExp(`^${firstPadd}`), '');
  }).join('\n');
}

export default class Markdown extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      sanitizedSource: decodeHTML(singleLineString([this.props.source]).replace(/<br\s*(|\/)>/g, '\n'))
    }
  }

  render() {
    return (
      <ContentWithMedia>
	<ReactMarkdown
	  source={this.state.sanitizedSource}
	  escapeHtml={false}
	  linkTarget="_blank"
	/>
      </ContentWithMedia>
    )
  }
}
