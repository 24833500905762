import { version as appVersion } from './version'

export const version = appVersion

export const APP_ENV = process.env.APP_ENV||(version.indexOf('dev')===-1&&'production')||'development'

// Firebase config

const firebaseProdConfig = {
  _isProduction: true,
  apiKey: "AIzaSyClrjm4A80KUjWoGG6-IWlPpx72gLnVwXQ",
  authDomain: "custportal-3000.firebaseapp.com",
  databaseURL: "https://custportal-3000.firebaseio.com",
  projectId: "custportal-3000",
  storageBucket: "custportal-3000.appspot.com",
  messagingSenderId: "58192393173"
}

const firebaseDevConfig = {
  apiKey: "AIzaSyDWHMl8akQOshQucSXm6_qqzEnEEw8yi68",
  authDomain: "custportal-3000-dev.firebaseapp.com",
  databaseURL: "https://custportal-3000-dev.firebaseio.com",
  projectId: "custportal-3000-dev",
  storageBucket: "custportal-3000-dev.appspot.com",
  messagingSenderId: "315987089802"
}

export const firebase = APP_ENV === 'production' ? firebaseProdConfig : firebaseDevConfig

if (APP_ENV === 'production' || firebase._isProduction) {
  if (window.location.host.indexOf('localhost') !== -1) {
    if (!window.confirm('WARNING!\n\nRunning production environment in localhost. Please confirm you know what you are doing!')) {
      throw 'Application terminated.'
    }
  }
}
else {
  console.log(`
  |----
  | APPLICATION: ${firebase.projectId}
  | Running in ${APP_ENV} environment.
  |----
  `)
}

export const appSettingsData = {
  events: 'event-settings',
  company: 'company_info',
}

// react-redux-firebase options
export const rrfConfig = {
  userProfile: 'users', // firebase root where user profiles are stored
  profileParamsToPopulate: [ // I don't know how this works or if it works...
    { child: 'roles', root: 'roles', keyProp: 'name' }
  ],
  profileFactory: (userData, profileData) => {
    const { user } = userData
    return {
      ...profileData,
      email: user.email || (user.providerData && user.providerData[0].email) || profileData.email || null,
      roles: {
        user: true
      },
      providerData: user.providerData || null,
    }
  },
  attachAuthIsReady: true, // attaches auth is ready promise to store
  useFirestoreForProfile: false, // Store in Firestore instead of Real Time DB
  enableLogging: false,

  // presence: 'presence', // where list of online users is stored in database
  // sessions: 'sessions' // where list of user sessions is stored in database (presence must be enabled)
}

export const api = {
  url: 'https://us-central1-io-notava.cloudfunctions.net/api'
}

export const sizes = {
  desktop: 1120,
  tablet: 840,
  tablet_portrait: 680,
  phone: 580,

  content_width: 620,
  content_width_narrow: 420,
}

export const format = {
  weekday: 'ddd',
  date_entry: 'DD-MM-YYYY',
  date: 'MMM Do YYYY',
  time: 'HH:mm',
  datetime: 'MMM Do YYYY, HH:mm',
  debug: 'YYYY-DD-MMTHH:mm:ssZZ',
}

export const locale = {
  locale: 'en-FI',
  weekStartDay: 'MONDAY',
}

export default { APP_ENV, version, firebase, rrfConfig, api, sizes, format, locale }
