
export async function importFirebase(modules=['auth', 'database']) {
  // dynamic import, will return promise
  // magic weback comment to get meaningfull chunkname
  const firebase = await import(/* webpackChunkName: 'firebase' */ 'firebase/app')
  // await Promise.all(modules.map(name => (
  //   import(/* webpackChunkName: 'firebase' */ `firebase/${name}`)
  // )))
  await Promise.all([
    modules.indexOf('auth') === -1 ? true : import(/* webpackChunkName: 'firebase' */ `firebase/auth`),
    modules.indexOf('database') === -1 ? true : import(/* webpackChunkName: 'firebase' */ `firebase/database`),
    modules.indexOf('storage') === -1 ? true : import(/* webpackChunkName: 'firebase' */ `firebase/storage`),
  ])
  return firebase
}
