import React, { Component } from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect, isLoaded } from 'react-redux-firebase'

import Spinner from '@atlaskit/spinner';
import ContentWrapper from '../components/ContentWrapper';

class SupportPage extends Component {

  render() {
    const { companyInfo } = this.props
    if (isLoaded(companyInfo)) {
      window.location.replace(companyInfo.support)
    }
    return (
      <ContentWrapper><Spinner size="large" /></ContentWrapper>
    )
  }
}

export default compose(
  firebaseConnect((props, store) => ([
    { type: 'once', path: 'company_info' },
  ])),
  connect(({firebase: {data, auth}}, props) => {
    return {
      companyInfo: data.company_info,
    }
  }),
)(SupportPage)
