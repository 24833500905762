import React, { Component } from 'react';
import PropTypes from 'prop-types'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'

import LinkButton from '../components/LinkButton';
import checkEmailVerification from '../store/checkEmailVerification'

class EmailVerificationRequestLink extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    addFlag: PropTypes.func.isRequired,
    dismissFlag: PropTypes.func.isRequired,
  }
  static propTypes = {
    firebase: PropTypes.shape({
      auth: PropTypes.func,
    }),
  }

  constructor(props) {
    super(props)
    this.state = {}
  }

  sendEmailVerification = async () => {
    let requestingFlagId = this.context.addFlag({
      title: 'Requesting verification email...',
      appearance: 'normal',
      autoDismiss: false,
    })
    try {
      await checkEmailVerification(this.props, true)
      this.addFlag({
        title: 'Verification email sent!',
        description: `Please check your inbox and follow the instructions to verify your email address.`,
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (err) {
      this.addFlag({
        title: 'Verification email error',
        description: (
          <div>
            <p>We couldn't send you verification email at this moment. Please try again later.</p>
            <p>{''+err}</p>
          </div>
        ),
        appearance: 'error',
        autoDismiss: true,
      })
    }
    this.dismissFlag(requestingFlagId)
  }

  addFlag = (...args) => this.context.addFlag.apply(this, args)
  dismissFlag = (...args) => this.context.dismissFlag.apply(this, args)

  render() {
    const { auth, children, ...props } = this.props
    return (
      <LinkButton onClick={this.sendEmailVerification} {...props}>{children}</LinkButton>
    )
  }
}

export default compose(
  firebaseConnect((props, store) => ([
  ])),
  connect(({firebase: {auth}}, props) => ({
    auth,
  })),
)(EmailVerificationRequestLink)
