// @flow
/**
 * Check email verification and send verification email if not sent.
 * props - (object) { auth, profile, firebase } 
 * forceSend - [optional](boolean) force send verification email
 */

type Auth = {
  emailVerified: boolean
}
type Profile = {
  isLoaded: boolean,
  lastVerificationEmailSent?: string
}
type Firebase = {
  auth(): { currentUser: any },
  updateProfile(): mixed
}

type Params = {
  auth: Auth,
  profile: Profile,
  firebase: Firebase
}

type Store = {
  firebase: Firebase,
  getState(): {
    firebase: { auth: Auth, profile: Profile }
  }
}

function getParams(paramStore: Params|Store): Params {
  if (paramStore.getState) {
    const state = paramStore.getState()
    return {
      auth: state.firebase.auth,
      profile: state.firebase.profile,
      firebase: paramStore.firebase
    }
  }
  else {
    return paramStore
  }
}

export default async function checkEmailVerification(paramStore: Params|Store, forceSend=false) {
  const { auth, profile, firebase } = getParams(paramStore)
  const currentUser = firebase.auth().currentUser
  if (!currentUser) {
    console.log('Not logged in.')
    return false
  }
  
  // Verify current id token is up to date
  const idToken = await currentUser.getIdTokenResult()
  if (idToken.claims.email_verified !== auth.emailVerified) {
    await currentUser.getIdToken(true)
  }

  // Check if already verified
  if (!forceSend) {
    if (auth.emailVerified) return true
    if (!profile.isLoaded) return false
    if (profile.lastVerificationEmailSent) return true
  }

  // Send verification
  await currentUser.sendEmailVerification()
  console.log('Verification email sent.')
  await firebase.updateProfile({
    lastVerificationEmailSent: new Date().toJSON(),
  })
  console.log('Last verification email sent: ' + new Date())
  return true
}

