import {
  createGlobalTheme,
} from '@atlaskit/navigation';
//import { Theme } from '@atlaskit/theme';
import { colors } from '../styles/colors';


export const globalTheme = createGlobalTheme("#ccc", colors.primaryColor)
export const containerTheme = createGlobalTheme("#fff", colors.secondaryColor)

export default function CustomTheme({children, props}) {
  return children
  /*
  return <Theme values={() => ({ backgroundColor: '#333', textColor: '#eee' })}>
    {children}
  </Theme>
*/
}
