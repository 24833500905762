import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import MainRouter from './modules/MainRouter';

// eslint-disable-next-line no-undef
ReactDOM.render(<MainRouter />, document.getElementById('app-root'));
