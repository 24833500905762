import { createStore, compose } from 'redux'
import { reactReduxFirebase } from 'react-redux-firebase'
import { importFirebase } from '../utils/firebase'

import rootReducer from '../reducer/'
import { firebase as fbConfig, rrfConfig, appSettingsData } from '../config'

const INITIAL_STATE = window.__INITIAL_STATE__ || { firebase: { authError: null } }

export default async function configureStore(initialState=INITIAL_STATE, history) {
  const firebase = await importFirebase()
  // Initialize Firebase instance
  firebase.initializeApp(fbConfig)

  const createStoreWithMiddleware = compose(
    reactReduxFirebase(firebase, rrfConfig),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
  )(createStore)

  const store = createStoreWithMiddleware(rootReducer, initialState)

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducer/', () => {
      const nextRootReducer = require('../reducer/')
      store.replaceReducer(nextRootReducer)
    })
  }

  store.getAppSettings = () => {
    const data = store.getState().firebase.data
    let settings = {isLoading: false}
    Object.entries(appSettingsData).forEach(([key, value]) => {
      let dat = data[value]
      settings[key] = dat
      settings.isLoading = settings.isLoading || !dat
    })
    return settings
  }

  return store
}

