import React from 'react';
import ContentWrapper from '../components/ContentWrapper';

import LoadingSpinner from '../components/LoadingSpinner';
import Middle from '../components/styled/Middle';

export default function LoadingPage({message}) {
  return (
    <ContentWrapper margin="2em auto">
      <Middle>
        <div style={{width: 'auto', padding: '2em 3em'}}>
          <LoadingSpinner />
        </div>
        <div style={{width: '100%'}}>
          {message}
        </div>
      </Middle>
    </ContentWrapper>
  )
}
