import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import Nav, {
  AkContainerTitle,
  AkNavigationItem,
  AkSearchDrawer,
  AkCreateDrawer,
} from '@atlaskit/navigation';
import DashboardIcon from '@atlaskit/icon/glyph/dashboard';
import BillingIcon from '@atlaskit/icon/glyph/billing';
import CalendarIcon from '@atlaskit/icon/glyph/calendar';
import EditorQuoteIcon from '@atlaskit/icon/glyph/editor/quote';
import IssuesIcon from '@atlaskit/icon/glyph/issues';
import GearIcon from '@atlaskit/icon/glyph/settings';
import HomeFilledIcon from '@atlaskit/icon/glyph/home-filled';
import SearchIcon from '@atlaskit/icon/glyph/search';
import PeopleIcon from '@atlaskit/icon/glyph/people';
import SignInIcon from '@atlaskit/icon/glyph/sign-in';
import AddonIcon from '@atlaskit/icon/glyph/addon';
import NotificationDirectIcon from '@atlaskit/icon/glyph/notification-direct';
import ArrowleftIcon from '@atlaskit/icon/glyph/arrow-left';

import CreateDrawer from '../components/CreateDrawer';
import SearchDrawer from '../components/SearchDrawer';

import { globalTheme, containerTheme } from './CustomTheme';
import HelpDropdownMenu from '../components/HelpDropdownMenu';
import AccountDropdownMenu from '../components/AccountDropdownMenu';
import RequireRole from '../components/RequireRole';
import appicon from '../images/appicon.png';


const NAV_LINKS = {
  publicNavLinks: [
    ['/', 'Home', DashboardIcon],
    ['/courses', 'Courses', CalendarIcon],
    ['/dance-pass', 'Dance Pass', AddonIcon],
    ['/library', 'Library', EditorQuoteIcon],
    null,
    ['/signin', 'Register & Sign In', SignInIcon],
  ],
  userNavLinks: [
    ['/', 'Home', DashboardIcon],
    ['/courses', 'Courses', CalendarIcon],
    ['/library', 'Library', EditorQuoteIcon],
    ['/dance-pass', 'Dance Pass', AddonIcon],
    null,
    ['/regs', 'My Registrations', IssuesIcon],
    ['/invoices', 'My Invoices', BillingIcon],
    ['/messages', 'Messages', NotificationDirectIcon],
    ['/settings', 'Settings', GearIcon],
  ],
  adminNavLinks: [
    ['/admin/users', 'Manage Users', PeopleIcon],
    ['/admin/regs', 'Manage Registrations', IssuesIcon],
    ['/admin/invoices', 'Manage Invoices', BillingIcon],
  ]
}

const renderNavLinks = (navLinks) => navLinks.map((link, index) => {
  if (!link) {
    return (<hr key={'hr-'+index} />)
  }
  const [url, title, Icon] = link;
  let active = false
  return (
    <NavLink key={url} to={url} exact={true} isActive={(m) => active = m}>
      <AkNavigationItem
        icon={<Icon label={title} size="medium" />}
        text={title}
        isSelected={active}
      />
    </NavLink>
  )
}, this)

const NavLinksPublic = () => renderNavLinks(NAV_LINKS.publicNavLinks)
const NavLinksUser = () => renderNavLinks(NAV_LINKS.userNavLinks)
const NavLinksAdmin = () => renderNavLinks(NAV_LINKS.adminNavLinks)

const backIcon = <ArrowleftIcon label="Back icon" size="medium" />;
const globalPrimaryIcon = <HomeFilledIcon label="Home icon" size="large" />;
const globalSearchIcon = <SearchIcon label="Search icon" />;

export default class StarterNavigation extends React.Component {
  state = {
  };

  static contextTypes = {
    navOpenState: PropTypes.object,
  };

  openDrawer = (openDrawer) => {
    this.setState({ openDrawer });
  };

  shouldComponentUpdate(nextProps, nextContext) {
    return true;
  };

  render() {
    return (
      <Nav
        isOpen={this.context.navOpenState.isOpen}
        width={this.context.navOpenState.width}
        onResize={this.props.onNavResize}
        globalTheme={globalTheme}
        containerTheme={containerTheme}
        containerHeaderComponent={() => (
          <AkContainerTitle
            text="Black Pepper Swing"
            subText="Portal"
            icon={<img alt="logo" src={appicon} />}
          />
        )}
        globalPrimaryIcon={globalPrimaryIcon}
        globalPrimaryItemHref="/"
        globalSearchIcon={globalSearchIcon}
        globalSecondaryActions={[<HelpDropdownMenu />, <AccountDropdownMenu />]}
        hasBlanket
        drawers={[
          <AkSearchDrawer
            backIcon={backIcon}
            isOpen={this.state.openDrawer === 'search'}
            key="search"
            onBackButton={() => this.openDrawer(null)}
            primaryIcon={globalPrimaryIcon}
          >
            <SearchDrawer
              onResultClicked={() => this.openDrawer(null)}
              onSearchInputRef={(ref) => {
                this.searchInputRef = ref;
              }}
            />
          </AkSearchDrawer>,
          <AkCreateDrawer
            backIcon={backIcon}
            isOpen={this.state.openDrawer === 'create'}
            key="create"
            onBackButton={() => this.openDrawer(null)}
            primaryIcon={globalPrimaryIcon}
          >
            <CreateDrawer
              onItemClicked={() => this.openDrawer(null)}
            />
          </AkCreateDrawer>
        ]}
        onSearchDrawerOpen={() => this.openDrawer('search')}
        onCreateDrawerOpen={() => this.openDrawer('create')}
      >
        <RequireRole role="any" orElse={<NavLinksPublic />}>
          <NavLinksUser />
        </RequireRole>
        <RequireRole role="admin">
          <hr/>
          <NavLinksAdmin />
        </RequireRole>
      </Nav>
    );
  }
}


/**
 * unused, disabled <Nav> properties

import SearchIcon from '@atlaskit/icon/glyph/search';
import CreateIcon from '@atlaskit/icon/glyph/add';
import ArrowleftIcon from '@atlaskit/icon/glyph/arrow-left';
import CreateDrawer from '../components/CreateDrawer';
import SearchDrawer from '../components/SearchDrawer';

const backIcon = <ArrowleftIcon label="Back icon" size="medium" />;

        globalSearchIcon={<SearchIcon label="Search icon" />}
        globalCreateIcon={<CreateIcon label="Create icon" />}
        drawers={[
          <AkSearchDrawer
            backIcon={backIcon}
            isOpen={this.state.openDrawer === 'search'}
            key="search"
            onBackButton={() => this.openDrawer(null)}
            primaryIcon={globalPrimaryIcon}
          >
            <SearchDrawer
              onResultClicked={() => this.openDrawer(null)}
              onSearchInputRef={(ref) => {
                this.searchInputRef = ref;
              }}
            />
          </AkSearchDrawer>,
          <AkCreateDrawer
            backIcon={backIcon}
            isOpen={this.state.openDrawer === 'create'}
            key="create"
            onBackButton={() => this.openDrawer(null)}
            primaryIcon={globalPrimaryIcon}
          >
            <CreateDrawer
              onItemClicked={() => this.openDrawer(null)}
            />
          </AkCreateDrawer>
        ]}
        onSearchDrawerOpen={() => this.openDrawer('search')}
        onCreateDrawerOpen={() => this.openDrawer('create')}
*/
