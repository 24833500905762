import React from 'react';
import { Link } from 'react-router-dom';

import AkDropdownMenu, { DropdownItemGroup, DropdownItem } from '@atlaskit/dropdown-menu';
import { AkGlobalItem } from '@atlaskit/navigation';
import HelpIcon from '@atlaskit/icon/glyph/question-circle';

const HelpDropdownMenu = () => (
  <AkDropdownMenu
    appearance="tall"
    position="right bottom"
    trigger={(
      <AkGlobalItem>
        <HelpIcon label="Help icon" />
      </AkGlobalItem>
    )}
  >
    <DropdownItemGroup title="Help">
      <Link to="/help/faq" target="_blank"><DropdownItem>FAQ</DropdownItem></Link>
      <Link to="/help" target="_blank"><DropdownItem>Support</DropdownItem></Link>
      <Link to="/help/terms" target="_blank"><DropdownItem>Terms and Privacy</DropdownItem></Link>
    </DropdownItemGroup>
  </AkDropdownMenu>
)

export default HelpDropdownMenu
