// @flow
import * as React from 'react';
import styled from 'styled-components';
import { SRLWrapper } from 'simple-react-lightbox'

const ContentWithMediaWrapper = styled.div`
  img, video, iframe {
    max-width: 100%;
  }
`

const SRLWrapperOptions = {
  transitionSpeed: 300,
  enablePanzoom: false,
}

export default function ContentWithMedia({children}) {
  return (
    <SRLWrapper {...SRLWrapperOptions}>
      <ContentWithMediaWrapper>
        {children}
      </ContentWithMediaWrapper>
    </SRLWrapper>
  )
}

