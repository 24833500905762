import styled from 'styled-components';

export const spacepx = 4
export const scalepx = 3
export const speed = '0.1s'

export const marginYpx = 2
export const paddingXpx = 24
export const paddingYpx = 16

export const Card = styled.div`
  background: #fff;
  color: #091E42;
  padding: ${paddingYpx}px ${paddingXpx}px;
  margin: ${marginYpx}px ${scalepx}px;
  border-radius: 3px;
  box-shadow: 0 4px 8px -2px rgba(9,30,66,0.25), 0 0 1px rgba(9,30,66,0.31);
  &:hover {
    padding: ${paddingYpx}px ${paddingXpx+scalepx}px;
    margin: ${marginYpx+spacepx}px 0;
  }
  transition: ${speed} margin, ${speed} padding;
`;

export const CardGroup = styled.div`
  margin: ${spacepx}px 0;
  &:hover {
    margin: 0 0;
  }
  transition: ${speed} margin;

  > hr {
    width: 2px;
    height: 2px;
  }
`;

export default Card
