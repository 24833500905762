import React from 'react';
import ContentWrapper from '../components/ContentWrapper';

export default function ErrorPage() {
  return (
    <ContentWrapper margin="1em auto">
      <h1>Sorry...</h1>
      <p>
        We were unable to load Portal or its page for you.
      </p>
      <p>
        It may be on us. Give us a little time and try again later, or go <a href="/">back to front page</a>.
      </p>
      <p>
        Also, please make sure your browser is <a href="/browser-not-supported.html">up-to-date</a>, or you may try another browser or device.
      </p>
      <hr/>
      <p>
        If problem persists, you can always contact us.
      </p>
      <p>
        Black Pepper Swing<br/>
        <a href="mailto:info@blackpepperswing.com">info@blackpepperswing.com</a><br/>
        <a href="+358841541724">+358 84 154 1724</a><br/>
      </p>
    </ContentWrapper>
  )
}
