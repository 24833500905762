// @flow
import React, { Component } from 'react';

import JoditEditor from 'jodit-react';

type Props = {
  content: string,
  onChange: func,
};
type State = {
  content: text
};


export default class ContentEditor extends Component<Props, State> {

  constructor(props) {
    super(props)
    this.state = {
      content: props.content || '',
    }
    this.config = {
      readonly: false, // all options from https://xdsoft.net/jodit/doc/,
      placeholder: 'Start typings...',
      toolbarAdaptive: false,
      buttons: [
        'paragraph', '|',
        'font',
        // 'fontsize',
        'bold',
        'strikethrough',
        'underline',
        'italic', '|',
        'align',  '|',
        'ul',
        'ol',
        'outdent', 'indent',  '|',
        // 'brush',
        'image',
        'video',
        'table',
        'link',
        'hr', '|',
        'undo', 'redo', '|',
        'eraser',
        // 'copyformat', '|',
        // 'symbol',
        'fullsize',
        // 'print',
        'source',
        'about',
      ],
    }
    this.editor = null
  }

  setContent(newContent) {
    this.setState({
      content: newContent
    })
    if (this.props.onChange) {
      this.props.onChange(newContent)
    }
  }

  render() {
    return (
      <JoditEditor
        ref={this.editor}
        value={this.state.content}
        config={this.config}
        tabIndex={1} // tabIndex of textarea
        // onBlur={newContent => this.setContent(newContent)} // preferred to use only this option to update the content for performance reasons
        onChange={newContent => this.setContent(newContent)}
      />
    )
  }
}

