import styled from 'styled-components';

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: ${props => props.wrap ? 'wrap' : 'nowrap'};
  gap: 0.85em;
  justify-content: ${props => props.justifyContent || 'space-between'};
`

const Flex = styled.div`
  flex-grow: ${props => props.grow};
`

export default FlexContainer
export {
  Flex
}
