import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import TermsPage from '../pages/TermsPage';
import TermsOfTradePage from '../pages/TermsOfTradePage';
import FAQPage from '../pages/FAQPage';
import SupportPage from '../pages/SupportPage';

export default class HelpRoute extends Component {

  render() {
    const { path } = this.props.match
    return (
      <Switch>
        <Route exact path={`${path}`} component={SupportPage} />
        <Route exact path={`${path}/terms`} component={TermsPage} />
        <Route exact path={`${path}/terms/trade`} component={TermsOfTradePage} />
        <Route exact path={`${path}/faq`} component={FAQPage} />
      </Switch>
    )
  }
}
