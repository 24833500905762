import React from 'react';
import styled from 'styled-components';

import { gridSize } from '@atlaskit/theme';

import { sizes } from '../config'

const Padding = styled.div`
  position: relative;
  ${props => props.margin ? `
    margin: ${props.margin};
    ` : `
    margin: ${gridSize() * 2}px auto;
    @media (min-width: ${sizes.desktop}px) {
      margin: ${gridSize() * 4}px ${gridSize() * 7}px;
    }
  `}
  ${props => props.maxWidth ? `
    max-width: ${props.maxWidth};
    ` : `
    max-width: ${sizes.content_width}px;
  `}
  ${props => props.padding ? `
    padding: ${props.padding};
  ` : `
    padding: ${gridSize() * 1}px ${gridSize() * 2}px ${gridSize() * 3}px ${gridSize() * 2}px;
  `}
`;

export default ({ margin, padding, maxWidth, fullWidth=false, children, ...rest }) => (
  <Padding margin={margin} padding={padding} maxWidth={maxWidth||(fullWidth===false?undefined:'100%')} {...rest}>{children}</Padding>
)
