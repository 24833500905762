import PropTypes from 'prop-types';
import React, { Component } from "react";

import { AkNavigationItemGroup, AkNavigationItem } from "@atlaskit/navigation";

import BacklogIcon from '@atlaskit/icon/glyph/backlog';
import LinkIcon from '@atlaskit/icon/glyph/link';

const createItems = [
  {
    title: "Add",
    items: [
      ['/modules/new', 'New Module', 'New Module', BacklogIcon],
      ['/sources/new', 'New Source', 'New Source', LinkIcon],
    ],
  },
];

export default class CreateDrawer extends Component {
  static propTypes = {
    onItemClicked: PropTypes.func,
  };

  render() {
    return (
      <div>
        {
          createItems.map(itemGroup => {
            return (
              <AkNavigationItemGroup key={itemGroup.title} title={itemGroup.title}>
                {
                  itemGroup.items.map(item => {
                    const [url, text, label, Icon] = item;
                    return (
                      <AkNavigationItem
                        key={url}
                        href={url}
                        icon={<Icon label={label}/>}
                        text={text.valueOf()}
                        onClick={this.props.onItemClicked}
                      />
                    );
                  })
                }
              </AkNavigationItemGroup>
            )
          })
        }
      </div>
    )
  };
}
