import styled from 'styled-components';
import { gridSize } from '@atlaskit/theme';

const Section = styled.div`
  padding-bottom: ${gridSize() * 6}px;
  ${props => `
    max-width: ${props.maxWidth||'none'};
  `}
`

export default Section
