import React, { Component } from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import PropTypes from 'prop-types'

import { Redirect } from 'react-router-dom';

import { AsyncSelect } from '@atlaskit/select';

import RequireRole from './RequireRole';

import { dataToArray, watchEvent, unWatchEvent } from '../utils/DataHelper'

export class OmniSearch extends Component {
  static propTypes = {
    firebase: PropTypes.shape({
      watchEvent: PropTypes.func.isRequired,
      unWatchEvent: PropTypes.func.isRequired,
    })
  }

  constructor(props) {
    super(props)
    this.state = {
      searchTimeout: null,
      redirect: null,
      q: '',
    }
  }

  onChangeSearch = (option) => {
    const { onChange } = this.props
    this.setState({
      redirect: option.path
    })
    onChange && onChange(option)
  }

  loadOptions = async (inputValue: string) => {
    clearTimeout(this.state.searchTimeout)

    this.setState({
      q: inputValue
    })

    if (inputValue.length < 3) return []

    return await new Promise((resolve) => {
      let timeout = setTimeout(() => {
        resolve(this.doSearch(inputValue))
      }, 300)
      this.setState({
        searchTimeout: timeout
      })
    })
  }

  doSearch = async (inputValue: string) => {
    const { firebase } = this.props
    let promises = []
    let searchEntries = [
      'users#email',
      'users#tel',
      'users#firstname',
      'users#lastname',
      'events#name',
      // 'invoices': 'user',
    ]
    searchEntries.forEach(function(entry) {
      const [ path, key ] = entry.split('#')
      promises.push(new Promise(async (resolve, reject) => {
        let params = {
          type: 'once',
          path: path,
          storeAs: `search_${path}_${key}`,
          queryId: `search_${path}_${key}`,
          queryParams: [
            'orderByChild='+key, `startAt=${inputValue.toUpperCase()}`,
            `endAt=${inputValue.toLowerCase()}\uf8ff`,
            // `limitToFirst=20`,
          ],
        }
        if(await watchEvent(firebase, params)) {
          unWatchEvent(firebase, params)
          resolve()
        } else {
          reject()
        }
      }))
    })

    if (await Promise.all(promises)) {
      return this.filteredOptions()
    }
  }

  filteredOptions() {
    let options = this.props.options||[]
    return options.filter((obj) => {
      return !!obj.search.match(new RegExp(this.state.q, 'i'))
    })
  }

  render() {
    const { options, onChange, ...restParams } = this.props

    if (this.state.redirect) {
      return (<Redirect to={this.state.redirect} />)
    }
    return (
      <RequireRole role="admin">
        <AsyncSelect
          className="async-select-with-callback"
          classNamePrefix="react-select"
          defaultOptions={this.filteredOptions()}
          loadOptions={this.loadOptions}
          onChange={this.onChangeSearch}
          cacheOptions={true}
          placeholder="Search..."
          noOptionsMessage={({inputValue}) => (
            !!inputValue.trim() ? 'Nothing found. Search everything.' : 'Search everything.'
          )}
          styles={{
            option: base => ({
              ...base, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'
            })
          }}
          {...restParams}
        />
      </RequireRole>
    )
  }
}

function mapUser(user) {
  return {
    type: 'users',
    path: `/admin/users/${user.key}`,
    value: user.key,
    label: (<span>{user.firstname} {user.lastname} <small>({user.email} / {user.tel}) {user.key}</small></span>),
    search: [user.firstname, user.lastname, user.email, user.tel].join(' '),
  }
}

function mapEvent(event) {
  return {
    type: 'events',
    path: `/courses/${event.key}`,
    value: event.key,
    label: (<span>{event.name} <small>({event.meta && event.meta.group})</small></span>),
    search: [event.name, JSON.stringify(event.meta)].join(' '),
  }
}

function removeDuplicates(options) {
  let values = new Set()
  return options.filter((obj) => {
    if (values.has(obj.path)) {
      return false
    }
    else {
      values.add(obj.path)
      return true
    }
  })
}

export default compose(
  firebaseConnect((props, store) => {
    return []
  }),
  connect(({firebase, firebase: {data, auth, profile}}, props) => {
    let options = [
      ...dataToArray(data.search_users_email).map(mapUser),
      ...dataToArray(data.search_users_firstname).map(mapUser),
      ...dataToArray(data.search_users_lastname).map(mapUser),
      ...dataToArray(data.search_users_tel).map(mapUser),
      ...dataToArray(data.search_events_name).map(mapEvent),
    ]
    return {
      options: removeDuplicates(options)
    }
  }),
)(OmniSearch)
