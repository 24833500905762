import React, { Component } from 'react';
import PropTypes from 'prop-types'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'

import Banner from '@atlaskit/banner';
import RequestLink from '../components/EmailVerificationRequestLink';


class EmailVerificationBanner extends Component {
  static propTypes = {
    firebase: PropTypes.shape({
      auth: PropTypes.func,
    }),
  }

  render() {
    const { auth } = this.props
    if (!auth.isLoaded || auth.isEmpty || auth.emailVerified) {
      return ''
    }
    return (
      <Banner isOpen={true} appearance="announcement">
        <p>
          Please verify your email address following the instructions on the email we sent you.
          <br/>
          <small style={{color: '#fff'}}>
            Didn't receive verification email? <RequestLink><span style={{color: '#fff'}}>Click here to request a new one.</span></RequestLink>
          </small>
        </p>
      </Banner>
    )
  }
}

export default compose(
  firebaseConnect((props, store) => ([
  ])),
  connect(({firebase: {auth}}, props) => ({
    auth,
  })),
)(EmailVerificationBanner)
