import React, { Component } from 'react';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info)
  }

  render() {
    const { title='Something went wrong', fallback='' } = this.props
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h1>{title}</h1>
          {fallback}
        </div>
      )
    }
    return this.props.children
  }
}
