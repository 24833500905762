import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import { Link } from 'react-router-dom';

import AkDropdownMenu, { DropdownItemGroup, DropdownItem } from '@atlaskit/dropdown-menu';
import { AkGlobalItem } from '@atlaskit/navigation';
import AkAvatar from '@atlaskit/avatar';

import RequireRole from './RequireRole';

const AccountDropdownMenu = ({ profile, firebase }) => (
  <RequireRole role="">
    <AkDropdownMenu
      appearance="tall"
      position="right bottom"
      trigger={(
      <AkGlobalItem>
        <AkAvatar size="small" />
      </AkGlobalItem>
      )}
    >
      <DropdownItemGroup title={`${profile.firstname} ${profile.lastname}`}>
        <Link to="/settings"><DropdownItem>View profile</DropdownItem></Link>
        <Link to="/signout"><DropdownItem>Sign out</DropdownItem></Link>
      </DropdownItemGroup>
    </AkDropdownMenu>
  </RequireRole>
)

export default compose(
  firebaseConnect((props, store) => ([
  ])),
  connect(({firebase, firebase: {auth, profile}}, props) => {
    return {
      profile: {key: auth.uid, ...profile}
    }
  }),
)(AccountDropdownMenu)
