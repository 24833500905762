import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment-timezone';
import Moment from 'react-moment'
import styled from 'styled-components';
import { firebaseConnect } from 'react-redux-firebase'

import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Flag, { AutoDismissFlag, FlagGroup } from '@atlaskit/flag';
import Page from '@atlaskit/page';

import SimpleReactLightbox from "simple-react-lightbox";

import '@atlaskit/css-reset';
import '../styles/global.css';

import { withRouter } from 'react-router-dom'

import ErrorBoundary from '../components/ErrorBoundary';
import DeviceDiv from '../components/DeviceDiv';
import StarterNavigation from '../components/StarterNavigation';
import MobileHeader from '../components/MobileHeader';
import LoadingSpinner from '../components/LoadingSpinner';
import EmailVerificationBanner from '../components/EmailVerificationBanner';
import UserAlertBanners from '../components/UserAlertBanners';

import { initExternalScripts } from '../components/ExternalScripts';

import ErrorPage from '../pages/ErrorPage';
import LoadingPage from '../pages/LoadingPage';

import { getSearchParam, isEmbedded, isDev } from '../utils/PageHelper';

const AppContainerElement = styled.div`
  @media print {
    div[class^="Page__Navigation-"] {
      display: none;
    }
    .no-print {
      display: none;
    }
  }
`

const OverflowContainer = styled.div`
  overflow: auto;
  min-height: 100vh;
`;

function AppContainer({children}) {
  return (
    <AppContainerElement>
      <ErrorBoundary title="" fallback={(<ErrorPage />)}>
        <SimpleReactLightbox>
          {children}
        </SimpleReactLightbox>
      </ErrorBoundary>
    </AppContainerElement>
  )
}

class App extends Component {
  state = {
    flags: [],
    lastFlagUpdate: null,
    modal: {
      content: null,
      actions: null,
    }
  };

  static contextTypes = {
    navOpenState: PropTypes.object,
    router: PropTypes.object,
    store: PropTypes.object.isRequired,
  };

  static propTypes = {
    location: PropTypes.object.isRequired,
    navOpenState: PropTypes.object,
    onNavResize: PropTypes.func,
  };

  static childContextTypes = {
    addFlag: PropTypes.func,
    dismissFlag: PropTypes.func,
    openModal: PropTypes.func,
    closeModal: PropTypes.func,
  }

  getChildContext() {
    return {
      addFlag: this.addFlag,
      dismissFlag: this.dismissFlag,
      openModal: this.openModal,
      closeModal: this.closeModal,
    };
  }

  constructor(props) {
    super(props)
    let cookies = getSearchParam('cookies', props) !== 'false'
    if (isDev()) cookies = false
    initExternalScripts({ cookies })
  }

  openModal = ({content=null, actions=null}) => {
    this.setState({
      modal: {
        content: content,
        actions: actions,
      }
    })
  }

  closeModal = () => {
    this.setState({
      modal: {
        content: null
      }
    })
  }

  /**
   * appearance:
   *   One of <
   *     "error",
   *     "info",
   *     "normal",
   *     "success",
   *     "warning"
   *   >
   */
  addFlag = ({title='', description='', appearance='normal', autoDismiss=false}) => {
    const lastUpdate = this.state.lastFlagUpdate
    const id = Date.now()
    const delay = lastUpdate && Math.abs(lastUpdate - id) < 500 ? 500 : 0
    setTimeout(() => {
      this.setState({
        lastFlagUpdate: Date.now(),
        flags: [{ id: id, title, description, appearance, autoDismiss }].concat(this.state.flags)
      })
    }, delay)
    return id
  }

  dismissFlag = (id) => this.onFlagDismissed(id)

  onFlagDismissed = (dismissedFlagId) => {
    this.setState({
      lastFlagUpdate: Date.now(),
      flags: this.state.flags.filter(flag => flag.id !== dismissedFlagId),
    })
  }

  renderGlobalBanners() {
    return (
      <React.Fragment>
        <EmailVerificationBanner />
        <UserAlertBanners />
      </React.Fragment>
    )
  }

  renderFlagGroup() {
    return (
      <div>
        <FlagGroup onDismissed={this.onFlagDismissed}>
          {this.state.flags.map(flag => {
            const FlagEl = !!flag.autoDismiss ? AutoDismissFlag : Flag
            return (
              <FlagEl
                id={flag.id}
                key={flag.id}
                title={flag.title}
                description={flag.description}
                appearance={flag.appearance}
              />
            )
          })}
        </FlagGroup>
      </div>
    )
  }

  renderModal() {
    let { modal } = this.state
    return (
      <ModalTransition>
        {modal.content && (
          <Modal
            actions={modal.actions}
            onClose={this.closeModal.bind(this)}
          >
            {modal.content}
          </Modal>
        )}
      </ModalTransition>
    )
  }

  render() {
    const { children } = this.props
    const appSettings = this.context.store.getAppSettings()
    if (appSettings.company && appSettings.company.timezone) {
      moment.tz.setDefault(appSettings.company.timezone)
      Moment.globalTimezone = appSettings.company.timezone
    }
    if (isEmbedded(this.props)) {
      if (appSettings.isLoading) {
        return (<LoadingSpinner />)
      }
      return this.props.children
    }
    return (
      <AppContainer>
        <Page
          navigation={<DeviceDiv only={['desktop']}><StarterNavigation /></DeviceDiv>}
        >
          <DeviceDiv only={['phone', 'tablet']}>
            <MobileHeader
              navigation={<StarterNavigation />}
            />
          </DeviceDiv>
          {this.renderGlobalBanners()}
          {this.renderFlagGroup()}
          {this.renderModal()}
          <OverflowContainer>
            {appSettings.isLoading ? <LoadingPage message="Loading app settings..." /> : children}
          </OverflowContainer>
        </Page>
      </AppContainer>
    );
  }
}

export default withRouter(compose(
  firebaseConnect((props, store) => ([
    { type: 'once', path: 'event-settings/groups' },
    { type: 'once', path: 'company_info' },
  ])),
  connect(({firebase: {data}}) => {
    return {data}
  }),
)(App))
