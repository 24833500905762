import React, { Component } from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'

import ContentWrapper from '../components/ContentWrapper';

class LibraryPage extends Component {

  render() {
    window.location.replace('https://library.blackpepperswing.com/')
    return (
      <ContentWrapper>
        <h1>BPS Library</h1>
        <p>
          While the coronavirus has us taking a break from parter dancing, we might all lack a bit of motivation and ideas. This year is our third year of regular classes and along that journey, we have accumulated many recaps from classes. We want to share those collections with you as we will also add new content for your practice at home.
        </p>
        <p>
          Take a look:<br/>
          <a href="https://library.blackpepperswing.com/">https://library.blackpepperswing.com/</a>
        </p>
      </ContentWrapper>
    )
  }
}

export default compose(
  firebaseConnect((props, store) => ([
  ])),
  connect(({firebase: {data, auth}}, props) => {
  }),
)(LibraryPage)
