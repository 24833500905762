import PropTypes from 'prop-types';
import React, { Component } from "react";
import SearchResults from "./SearchResults";
import OmniSearch from "./OmniSearch";
import RequireRole from '../components/RequireRole';

const items = [
  { name: 'Courses', link: '/courses', description: 'Course schedule.' },
  { name: 'My Registrations', link: '/regs', description: 'All your registrations.' },
  { name: 'My Invoices', link: '/invoices', description: 'All your invoices.' },
  { name: 'Messages', link: '/messages', description: 'All your messages.' },
  { name: 'Settings', link: '/settings', description: 'Settings, including your details and BPS Credits.' },
  { name: 'Support', link: '/help', description: 'Support pages including terms and conditions, contact info, and other useful information.' },
  { name: 'FAQ', link: '/help/faq', description: 'Most frequently asked questions.' },
  { name: 'Sign in / Create an account', link: '/signin', description: 'Create (or log in to) your BPS Portal account to register for courses.' },
];

export default class SearchDrawer extends Component {
  static propTypes = {
    onResultClicked: PropTypes.func,
    onSearchInputRef: PropTypes.func,
  };

  state = {
    searchString: '',
    results: items,
  };

  filterChange = () => {
    this.setState({
      searchString: this.searchInput.value
    });
  };

  searchResults = () => {
    const {results, searchString} = this.state;

    const matchingResults = results.filter(
      c => (
        c.name.toLowerCase().indexOf(searchString.toLowerCase()) >= 0 ||
        (c.description && c.description.toLowerCase().indexOf(searchString.toLowerCase()) >= 0)
      )
    ).slice(0, 10);

    return (
      <SearchResults
        matchingResults={matchingResults}
        onResultClicked={() => {
          this.props.onResultClicked();
          this.searchInput.value = '';
          this.filterChange();
        }}
      />
    )
  };

  onChangeSearch = (option) => {
    setTimeout(() => {
      this.props.onResultClicked()
    }, 300)
  }

  render() {
    return (
      <div>
        <RequireRole role="admin">
          <OmniSearch onChange={this.onChangeSearch} />
        </RequireRole>

        <input
          type="text"
          placeholder="Search..."
          onKeyUp={this.filterChange}
          ref={el => {
            this.searchInput = el;
            if (this.props.onSearchInputRef) {
              this.props.onSearchInputRef(el);
            }
          }}
          style={{
            border: 'none',
            display: 'block',
            fontSize: 24,
            fontWeight: 200,
            outline: 'none',
            padding: '0 0 0 12px',
          }}
        />
        { this.searchResults() }
      </div>
    )
  }
}
