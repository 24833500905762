import PropTypes from 'prop-types';
import React, { Component, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import { Provider } from 'react-redux'

import Page from '@atlaskit/page';

import App from './App';
import AboutFooter from '../components/AboutFooter';
import { isEmbedded } from '../utils/PageHelper';
import configureStore from '../store/'

import LoadingPage from '../pages/LoadingPage';

import HomePage from '../pages/HomePage';
import LibraryPage from '../pages/LibraryPage';
import DancePassPage from '../pages/DancePassPage';
import HelpRoute from '../routes/help.route';

// import SignInPage from '../pages/SignInPage';
// import SignOutPage from '../pages/SignOutPage';
// import SignUpPage from '../pages/SignUpPage';
// import AdminRoute from '../routes/admin.route';
// import CoursesRoute from '../routes/courses.route';
// import InvoicesRoute from '../routes/invoices.route';
// import ForgotPasswordPage from '../pages/ForgotPasswordPage';

// const HomePage = lazy(() => import('../pages/HomePage'))
const SignInPage = lazy(() => import(/* webpackChunkName: "signupandall" */ '../pages/SignInPage'))
const SignOutPage = lazy(() => import(/* webpackChunkName: "signupandall" */ '../pages/SignOutPage'))
const SignUpPage = lazy(() => import(/* webpackChunkName: "signupandall" */ '../pages/SignUpPage'))
const ForgotPasswordPage = lazy(() => import(/* webpackChunkName: "signupandall" */ '../pages/ForgotPasswordPage'))
const AdminRoute = lazy(() => import('../routes/admin.route'))
const CoursesRoute = lazy(() => import('../routes/courses.route'))
const InvoicesRoute = lazy(() => import('../routes/invoices.route'))
const LoggedinRoute = lazy(() => import('../routes/loggedin.route'))

/**
 * Component that watches route changes.
 *
 * For embedded paths, opens non-embedded pages
 * in a new window.
 */
class RouteWatcherComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: null
    }
  }

  shouldComponentUpdate(nextProps) {
    if (isEmbedded(this.props) && !isEmbedded(nextProps)) {
      window.open(window.location.href, '_blank')
      this.props.history.goBack()
      return false
    }
    return true
  }

  render() {
    return (
      <Page>
        {this.props.children}
        {isEmbedded(this.props) && (<AboutFooter />)}
      </Page>
    )
  }
}

const RouteWatcher = withRouter(RouteWatcherComponent)

export default class MainRouter extends Component {
  constructor() {
    super()
    this.state = {
      store: null,
      navOpenState: {
        isOpen: true,
        width: 304,
      }
    }
  }

  getChildContext () {
    return {
      navOpenState: this.state.navOpenState,
    }
  }

  async componentDidMount() {
    const store = await configureStore()
    this.setState({
      store
    })
  }

  onNavResize = (navOpenState) => {
    this.setState({
      navOpenState,
    })
  }

  render() {
    const { store } = this.state
    if (!store) return (<LoadingPage message="Initializing..." />)
    return (
      <Provider store={store}>
        <Router>
          <RouteWatcher>
            <App onNavResize={this.onNavResize}>
              {this.doRouting()}
            </App>
          </RouteWatcher>
        </Router>
      </Provider>
    )
  }

  doRouting() {
    return (
      <Suspense fallback={<LoadingPage message="Loading page..." />}>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/home" component={HomePage} />
          <Route exact path="/library" component={LibraryPage} />
          <Route exact path="/dance-pass" component={DancePassPage} />

          <Route exact path="/signup" component={SignUpPage} />
          <Route exact path="/signin" component={SignInPage} />
          <Route exact path="/signin/embed" component={SignInPage} />
          <Route exact path="/signout" component={SignOutPage} />
          <Route exact path="/forgot-password" component={ForgotPasswordPage} />

          <Route path="/help" component={HelpRoute} />
          <Route path="/courses" component={CoursesRoute} />
          <Route path="/invoices" component={InvoicesRoute} />
          <Route path="/admin" component={AdminRoute} />
          <Route path="/" component={LoggedinRoute} />
        </Switch>
      </Suspense>
    )
  }
}

MainRouter.childContextTypes = {
  navOpenState: PropTypes.object,
}
